<template>
  <div class="service-details-wrapper">
      <div class="banner-img-wrapper">
          <h2 class="cover-title">{{ $t('configuration.resource_pool') }}</h2>
          <img src="@/assets/images/cover-img.jpg" alt="">
      </div>
      <!-- Breadcraumb  -->
      <div>
          <b-container>
              <ol class="breadcrumb custom-bread">
                  <li class="breadcrumb-item"><router-link to="/portal/home" target="_self" class="">{{ $t('portal.home') }}</router-link></li>
                  <li class="breadcrumb-item"><router-link to="/portal/all-services" target="_self" class="">{{ $t('portal.all_service') }}</router-link></li>
                  <li @click="$router.go(-1)" class="breadcrumb-item"><router-link to="" target="_self">{{ $t('portal.service_details') }}</router-link></li>
                  <li class="breadcrumb-item active"><span aria-current="location">{{ $t('configuration.resource_pool') }}</span></li>
              </ol>
          </b-container>
      </div>
      <div class="service-description">
        <b-container>
          <body-card>
            <!-- table section start -->
            <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('configuration.resource_pool') }} {{ $t('globalTrans.entry') }}</h4>
            </template>
            <template v-slot:body>
              <b-overlay :show="loading">
                <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                  <b-overlay :show="loading">
                    <b-form @submit.prevent="handleSubmit(finalSave)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
                      <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                          <ValidationProvider name="Organization" vid="area_of_interest_id" rules="required|min_value:1">
                            <b-form-group
                              label-for="area_of_interest_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('configuration.area_of_interest')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="form.area_of_interest_id"
                              :options="areaOfInterest"
                              id="area_of_interest_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6">
                          <ValidationProvider name="Name (En)" vid="name_en" rules="required" v-slot="{ errors }">
                            <b-form-group
                              label-for="name_en">
                              <template v-slot:label>
                                {{ $t('configuration.name') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="name_en"
                                v-model="form.name_en"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6">
                          <ValidationProvider name="Name (Bn)" vid="name_bn" rules="" v-slot="{ errors }">
                            <b-form-group
                              label-for="name_bn">
                              <template v-slot:label>
                                {{ $t('configuration.name') }} {{ $t('globalTrans.bn') }}
                              </template>
                              <b-form-input
                                id="name_bn"
                                v-model="form.name_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6">
                          <ValidationProvider name="Designation (en)" vid="designation" rules="required" v-slot="{ errors }">
                            <b-form-group
                              label-for="designation">
                              <template v-slot:label>
                                {{ $t('configuration.designation') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="designation"
                                v-model="form.designation"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6">
                          <ValidationProvider name="Designation (Bn)" vid="designation_bn" v-slot="{ errors }">
                            <b-form-group
                              label-for="designation_bn">
                              <template v-slot:label>
                                {{ $t('configuration.designation') }} {{ $t('globalTrans.bn') }}
                              </template>
                              <b-form-input
                                id="designation_bn"
                                v-model="form.designation_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6">
                          <ValidationProvider name="Organization (En)" vid="organization" rules="required" v-slot="{ errors }">
                            <b-form-group
                              label-for="organization">
                              <template v-slot:label>
                                {{ $t('configuration.organization') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="organization"
                                v-model="form.organization"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6">
                          <ValidationProvider name="Organization (Bn)" vid="organization_bn" v-slot="{ errors }">
                            <b-form-group
                              label-for="organization_bn">
                              <template v-slot:label>
                                {{ $t('configuration.organization') }} {{ $t('globalTrans.bn') }}
                              </template>
                              <b-form-input
                                id="organization_bn"
                                v-model="form.organization_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6">
                          <ValidationProvider name="Contact No" vid="contact_no" rules="required|min:11|max:11" v-slot="{ errors }">
                            <b-form-group
                              label-for="contact_no">
                              <template v-slot:label>
                                {{ $t('configuration.contact_no') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="contact_no"
                                type="number"
                                v-model="form.contact_no"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6">
                          <ValidationProvider name="Email" vid="email" rules="required" v-slot="{ errors }">
                            <b-form-group
                              label-for="email">
                              <template v-slot:label>
                                {{ $t('configuration.email') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="email"
                                v-model="form.email"
                                type="email"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6">
                          <ValidationProvider name="Area Of Specialization (En)" vid="area_of_specialization_en" v-slot="{ errors }" rules="required">
                            <b-form-group
                              label-for="area_of_specialization">
                              <template v-slot:label>
                                {{ $t('configuration.area_of_specialization') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-textarea
                                  id="area_of_specialization"
                                  rows="3"
                                  v-model="form.area_of_specialization_en"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6">
                          <ValidationProvider name="Area Of Specialization (Bn)" vid="area_of_specialization_bn" v-slot="{ errors }">
                            <b-form-group
                              label-for="area_of_specialization">
                              <template v-slot:label>
                                {{ $t('configuration.area_of_specialization') }} {{ $t('globalTrans.bn') }}
                              </template>
                              <b-form-textarea
                                  id="area_of_specialization"
                                  rows="3"
                                  v-model="form.area_of_specialization_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6">
                          <ValidationProvider name="General Expertise (En)" vid="general_expertise_en" v-slot="{ errors }" rules="required">
                            <b-form-group
                              label-for="general_expertise_en">
                              <template v-slot:label>
                                {{ $t('configuration.general_expertise') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-textarea
                                  id="general_expertise_en"
                                  rows="3"
                                  v-model="form.general_expertise_en"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6">
                          <ValidationProvider name="General Expertise (Bn)" vid="general_expertise_bn" v-slot="{ errors }">
                            <b-form-group
                              label-for="general_expertise_bn">
                              <template v-slot:label>
                                {{ $t('configuration.general_expertise') }} {{ $t('globalTrans.bn') }}
                              </template>
                              <b-form-textarea
                                  id="general_expertise_bn"
                                  rows="3"
                                  v-model="form.general_expertise_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6">
                          <ValidationProvider name="Image" vid="image"  :rules="id ? '' : 'required'" v-slot="{ errors }">
                              <b-form-group
                                label-for="photo"
                              >
                              <template v-slot:label>
                                {{ $t('configuration.image')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-file
                                  id="upload_image"
                                  v-on:change="handlePhoto"
                                  accept=".png,.jpeg,.jpg"
                                  v-model="form.image"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-file>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6">
                        <ValidationProvider name="Attachment" vid="attachment" :rules="id ? '' : 'required'" v-slot="{ errors }">
                          <b-form-group
                              label-for="attachment">
                              <template v-slot:label>
                                  {{ $t('configuration.curriculum_vitae') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-file
                                  id="attachment"
                                  v-model="form.file"
                                  @change="onChange"
                                  accept=".pdf"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  placeholder="Choose a file or drop it here..."
                                  drop-placeholder="Drop file here..."
                              ></b-form-file>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      </b-row>
                      <b-row class="text-right">
                        <b-col>
                            <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
                            <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
                        </b-col>
                      </b-row>
                    </b-form>
                  </b-overlay>
                </ValidationObserver>
              </b-overlay>
            </template>
            <!-- table section end -->
          </body-card>
        </b-container>
      </div>
      <b-modal ref="mymodal" id="modal-4" size="md" :title="$t('globalTrans.otp')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <br/>
          <b-overlay :show="loadModal">
          <ValidationObserver ref="otp"  v-slot="{ handleSubmit, reset }">
              <b-form id="otp" @submit.prevent="handleSubmit(otpCheckData)" @reset.prevent="reset" >
              <b-row>
                  <h2 class="col-lg-12 text-center" v-if="parseInt(timer) > 0">{{$t('globalTrans.timer')}} : {{ timer | minutesAndSeconds }}</h2>
                  <h2 class="col-lg-12 text-center" v-else>{{$t('globalTrans.please_resend_otp')}}</h2>
                  <b-col lg="12" sm="12">
                  <b-col lg="12" sm="12">
                      <ValidationProvider name="Otp"  vid="otp" rules="required">
                      <div slot-scope="{ valid, errors }">
                          <b-form-input
                          id="otp"
                          v-model="otpCheck"
                          :placeholder="$t('globalTrans.otp')"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                          {{ errors[0] }}
                          </div>
                      </div>
                      </ValidationProvider>
                  </b-col>
                  </b-col>
                  <br/>
                  <b-col lg="12" sm="12" style="padding-left: 30px;padding-top:10px">
                      <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.verify') }}</b-button>
                      <b-button type="button" variant="warning" class="mr-2 lg-3" @click="reOtpSend">{{ $t('globalTrans.resend_code') }}</b-button>
                  </b-col>
              </b-row>
              </b-form>
          </ValidationObserver>
          </b-overlay>
        <br/>
      </b-modal>
  </div>
</template>
<script>
import RestApi, { bftiResReportServiceBaseUrl } from '@/config/api_config'
const TWO_MINITES = 60 * 2
export default {
  name: 'Form',
  props: ['id'],
  data () {
    return {
      valid: null,
      saveBtnName: this.$t('globalTrans.save'),
      errors: [],
      loading: false,
      form: {
        area_of_interest_id: 0,
        name_en: '',
        name_bn: '',
        designation: '',
        designation_bn: '',
        organization: '',
        organization_bn: '',
        contact_no: '',
        email: '',
        area_of_specialization_en: '',
        area_of_specialization_bn: '',
        general_expertise_bn: '',
        general_expertise_en: '',
        image: [],
        curriculum: [],
        remarks: '',
        remarks_bn: '',
        source: 1,
        status: 2
      },
      upload_image: [],
      upload_curriculum: [],
      areaOfInterest: [],
      loadModal: false,
      showMessage: false,
      showAgeMessage: false,
      otpCheck: '',
      countShow: false,
      TWO_MINITES: TWO_MINITES,
      timer: 0,
      otpSentFirst: true
    }
  },
  created () {
    this.loadData()
  },
  filters: {
    minutesAndSeconds (value) {
      var minutes = Math.floor(parseInt(value, 10) / 60)
      var seconds = parseInt(value, 10) - minutes * 60
      return `${minutes}:${seconds}`
    }
  },
  mounted () {
    setInterval(() => {
      this.timer -= 1
      if (this.timer === 0) {
        this.otpDelete()
      }
    }, 1000)
  },
  methods: {
    async loadData () {
      this.loading = true
      const result = await RestApi.getData(bftiResReportServiceBaseUrl, '/portal-dropdowns')
      if (result.success) {
        this.areaOfInterest = result.data.areaOfInterestList.map(item => {
          const tmp = this.$i18n.locale === 'bn' ? { text: item.text_bn } : { text: item.text_en }
          return Object.assign({}, item, tmp)
        })
        this.loading = false
      }
    },
    onChange (event) {
      const input = event.target
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.form.attachment = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      } else {
        this.form.attachment = ''
      }
    },
     handlePhoto (e) {
      const selectedPhoto = e.target.files[0]
      const reader = new FileReader()
      reader.onload = (event) => {
        this.form.image = event.target.result
      }
      reader.readAsDataURL(selectedPhoto)
    },
    onFileChange (e) {
      this.image = e.target.files[0]
    },
    async save () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      result = await RestApi.postData(bftiResReportServiceBaseUrl, 'resource-pool/store', this.form)

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.$i18n.locale === 'bn' ? 'আপনার অনুরোধ সফল হয়েছে।' : 'Your request has been successful.',
          color: '#D6E09B'
        })
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
      this.form = {}
      this.$refs.form.reset()
    },
    finalSave () {
        this.$swal({
            title: this.$t('configuration.final_save_mess'),
            showCancelButton: true,
            confirmButtonText: this.$t('globalTrans.yes'),
            cancelButtonText: this.$t('globalTrans.no'),
            focusConfirm: false
        }).then((result) => {
            if (result.isConfirmed) {
                this.otpSend()
            }
        })
    },
    async otpCheckData () {
      this.loadModal = true
      let result = null
      const ottpSend = {
        mobile: this.form.contact_no,
        otp: this.otpCheck
      }
      result = await RestApi.postData(bftiResReportServiceBaseUrl, 'portal/resource-pool-otp/otp-check', ottpSend)
      if (result.success) {
          this.$refs.mymodal.hide()
            this.save()
            this.loadModal = false
      } else {
            this.otpCheck = ''
            this.loadModal = false
            this.$toast.error({
                title: 'Error',
                message: 'Not Matched'
            })
            this.$refs.form.setErrors(result.errors)
      }
    },
    async otpDelete () {
      let result = null
      const ottpSend = {
        mobile: this.form.contact_no,
        otp: this.otpCheck
      }
      result = await RestApi.postData(bftiResReportServiceBaseUrl, 'portal/resource-pool-otp/otp-delete', ottpSend)
      if (result.success) {
      } else {
        this.$toast.error({
          title: 'Error',
          message: 'Not Matched'
        })
      }
    },
    async otpSend () {
        this.loading = true
        this.loadModal = true
        let result = null
        const ddta = {
            mobile: this.form.contact_no
        }
        result = await RestApi.postData(bftiResReportServiceBaseUrl, 'portal/resource-pool-otp/otp', ddta)
        this.loading = false
        if (result.success) {
            this.$toast.success({
            title: 'Success',
            message: this.$t('globalTrans.a_otp_send_your_mobile'),
            color: '#D6E09B'
            })
            this.$refs.mymodal.show()
            this.timer = this.TWO_MINITES
            this.loadModal = false
        } else {
            this.$refs.form.setErrors(result.errors)
            this.loadModal = false
        }
    },
    async reOtpSend () {
      this.loadModal = true
      let result = null
      result = await RestApi.postData(bftiResReportServiceBaseUrl, 'portal/resource-pool-otp/otp', { mobile: this.form.contact_no })
      if (result.success) {
        this.timer = this.TWO_MINITES
        this.$toast.success({
          title: 'Success',
          message: this.$t('globalTrans.a_otp_send_your_mobile'),
          color: '#D6E09B'
        })
        this.loadModal = false
      } else {
        this.$refs.form.setErrors(result.errors)
        this.loadModal = false
      }
    }
  }
}
</script>
